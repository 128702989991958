import React, { createContext, useContext, useMemo, useState } from 'react';

type TUserSession = {
  token: string | null;
}

type AuthContextTypes = {
  isLoggedIn: boolean;
  userSession: TUserSession;
  logout: () => void;
  setUserSession: (user: any) => void;
}

const initialValue: AuthContextTypes = {
  isLoggedIn: false,
  userSession: { token: null },
  logout: () => {},
  setUserSession: () => {},
};

const AuthContext = createContext<AuthContextTypes>(initialValue);

export const AuthProvider: React.FC<any> = ({ children }) => {
  const [userSession, setUserSession] = useState<any>(null);

  const logout = () => {
    window.localStorage.removeItem('auth:token');
    window.localStorage.removeItem('auth:root');
    setUserSession(null);
  }

  const authProviderValue = useMemo(
    () => ({
      ...initialValue,
      userSession,
      logout,
      setUserSession,
    }),
    [userSession],
  );

  return (
    <AuthContext.Provider value={authProviderValue}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuthContext = () => {
  const ctx = useContext(AuthContext);

  if (typeof ctx === 'undefined') {
    throw new Error('useAuthContext must be used within AuthProvider');
  }

  return ctx;
};