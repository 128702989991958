import { useMutation } from 'react-query';

import { useAxiosContext } from 'contexts/AxiosContext';

type TCredentials = {
  email: string;
  password: string;
}

const useLogin = () => {
  const { axios } = useAxiosContext();

  return useMutation(
    (credentials: TCredentials) => axios.post('/users/login', credentials),
    {
      onSuccess: ({ data }) => {
        window.localStorage.setItem('auth:token', data.token);
        window.localStorage.setItem('auth:root', data.user);
      },
    }
  );
};

export default useLogin;
