import { useCallback, useEffect, useState } from "react";
import axios from 'axios';
import moment from 'moment';
import { get, map } from 'lodash';
import {
  Box,
  Button,
  Heading,
  Input,
  Text,
  Flex,
  IconButton,
  Table,
  TableCaption,
  Thead,
  Td,
  Tr,
  Th,
  Tbody,
  Skeleton,
  Stack,
  Tabs, TabList, TabPanels, Tab, TabPanel, Center, HStack,
} from '@chakra-ui/react';

import { EditIcon } from '@chakra-ui/icons';

import { API } from '../constants';

import EditPatientDialog from './components/EditPatientDialog';
import EmbededScheduler from './components/EmbededScheduler';

import useBookSecondDose from './hook-queries/useBookSecondDose';

import useDebouncedSearch from './useDebouncedSearch';
import CancelConfirmationDialog from './CancelConfirmationDialog';

interface SearchStepProps {
  onNext: (data: any) => any;
}

const postCancelAppointment = (token: string, appointmentId: string, callback?: any) => 
  axios.put(API + `/appointments/${appointmentId}/cancel`, {}, {
    headers: {
      'x-access-token': token,
    },
  })
    .then(res => callback(res.data))
    .catch(err => console.log('err', err)
  );

const getPatientByID = (token: string, patientId: string, callback: any,) => 
  axios.get(API + `/patients/${patientId}`, {
    headers: {
      'x-access-token': token,
    },
  })
    .then(res => callback(res.data))
    .catch(err => console.log('err', err)
  );

const getPatientByFullSearch = async (token: string, value: string) => {
  const { data } = await axios.get(API + `/patients/search?type=fullSearch&value=${value}`, {
    headers: {
      'x-access-token': token,
    },
  });
  return data;
};

const getAppointmentsByPatient = (token: string, patientId: string, callback: any) => {
  axios.get(`${API}/appointments/crudlist?patient=${patientId}&sort=date&sortBy=desc`, {
    headers: {
      'x-access-token': token,
    },
  })
    .then(({ data }: any) => {
      const facilityIds = data.response.map((item: any) => item && item.facility).filter((item: any, index: number, aux: any) => aux.indexOf(item) === index);
      const facilityRequests = facilityIds.map((facility: string) => axios.get(`${API}/facilities/${facility}?includeDeleted=true`, {
        headers: {
          'x-access-token': token,
        },
      }));
      axios.all([...facilityRequests])
        .then((responses) => {
          const facilities = responses ? responses.map(({ data }: any) => data) : [];
          const appointments = data.response.map((item: any) => ({
            ...item,
            facility: facilities.find((facility: any) => facility._id === item.facility) || { name: item.facility },
          }));
          callback(appointments);
        })
    });
}

const putAppointmentArrived = (token: string, appointment: any, callback: any) => {
  axios.put(`${API}/appointments/${appointment._id}/arrive`, {}, {
    headers: {
      'x-access-token': token,
    },
  })
      .then(() => {
        callback();
      })
      .catch((err: any) => {
        console.log('something went wrong', err);
      });
}

function parseDate(value: any, format: string) {
  if (!value) return '';

  if (value.year) {
    const { year, month, day } = value;

    return moment(`${year}-${month}-${day}`, 'YYYY-MM-DD').format(format || 'LL');
  }

  return moment(value, 'YYYY-MM-DD hh:mm').format(format || 'LL');
}

const SearchStep: React.FC<SearchStepProps> = ({ onNext }) => {
  const { mutation, query: { data } } = useBookSecondDose();
  const authToken: string = window.localStorage.getItem('auth:token') || '';
  const useSearchPatient = () => useDebouncedSearch((text: string) => getPatientByFullSearch(authToken, text))
  const { inputText, setInputText, searchResults } = useSearchPatient();
  const [qrCodeValue, setQrCodeValue] = useState('');
  const [patient, setPatient] = useState<any>();
  const [patientToEdit, setPatientToEdit] = useState<any>();
  const [appointments, setAppointments] = useState([]);
  const [appointmentsLoading, setAppointmentsLoading] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState('');

  const handleScanQrChange = useCallback((value: any) => {
    setAppointmentsLoading(true);
    if (value.length > 24) {
      const patientId = JSON.parse(value).patient;
      getPatientByID(authToken, patientId, setPatient);
    } else {
      getPatientByID(authToken, value, setPatient);
    }
  }, [authToken]);

  useEffect(() => {
    if(patient) {
      setAppointmentsLoading(true);
      const handleAppointments = (appointments: any) => {
        setAppointments(appointments);
        setAppointmentsLoading(false);
      }
      getAppointmentsByPatient(authToken, patient._id, handleAppointments);
    }
  }, [authToken, patient]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (qrCodeValue.length > 1) {
        handleScanQrChange(qrCodeValue);
        setQrCodeValue('');
      }
     }, 1000);
 
    return () => {
      clearTimeout(timeout);
    };
   },[qrCodeValue, handleScanQrChange, setQrCodeValue]);

  const handleOnTabChange = () => {
    setQrCodeValue('');
    setAppointments([]);
    setPatient(null);
  }

  const handleSearchPatientChange = (event: any) => {
    const { value } = event.target;
    handleOnTabChange();
    setInputText(value);
  };

  const handlePatientCheckIn = (appointment: any) => {
    putAppointmentArrived(authToken, appointment, onNext({ patient, appointment }))
  };

  const handleOpenScheduler = () => window.open(
    'https://patient.lumahealth.io/survey?patientFormTemplate=601b6e60ff03d10012c7769c&user=601862d6447bbd001abb8540&isOnPatientBehalf=true',
    '_blank'
  );

  const handleBookSecondDose = (patientId: string) => {
    mutation.mutate(patientId, {
      onSuccess: (response) => {
        const patientFormTemplateId = get(data, 'data.patientFormTemplate');
        const patientToken = get(response, 'data.token');
        window.open(
          `https://patient.lumahealth.io/survey?patientFormTemplate=${patientFormTemplateId}&token=${patientToken}`,
          '_blank'
        );
      },
    })
  }

  const handleCancelAppointment = (appointmentId: string) => {
    setAppointmentsLoading(true);
    postCancelAppointment(authToken, appointmentId, (appointment: any) => {
      setAppointmentsLoading(false);
      const appointmentsList: any = appointments.map((item: any) =>
        item._id === appointmentId
          ? { ...item, status: appointment.status }
          : item
      );
      setAppointments(appointmentsList);
    });
  };

  return(
    <>
      <Flex flexDirection="column">
        <Box width="1200px">
          <Tabs isFitted variant="soft-rounded" onChange={handleOnTabChange}>
            <TabList>
              <Tab>Scan QR</Tab>
              <Tab>Search Patient</Tab>
              <Tab onClick={handleOpenScheduler}>Open Scheduler</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Input
                  autoFocus
                  focusBorderColor="blue.200"
                  type="text"
                  name="scanQr"
                  size="md"
                  value={qrCodeValue}
                  onChange={(event) => setQrCodeValue(event.target.value)}
                />
              </TabPanel>
              <TabPanel>
                <Input
                  autoFocus
                  focusBorderColor="blue.200"
                  type="text"
                  name="searchPatient"
                  size="md"
                  placeholder="Search by name, phone number or date of birth"
                  value={inputText}
                  onChange={handleSearchPatientChange}
                />
                {searchResults.status === "loading" &&
                  <Stack marginTop={12}>
                    <Skeleton height="20px" />
                    <Skeleton height="20px" />
                    <Skeleton height="20px" />
                  </Stack>
                }
                {
                  patient && appointments.length === 0 && !appointmentsLoading &&
                  <Center marginTop={6}>
                    <Box textAlign="center" marginBottom="8">
                      <Heading>Patient</Heading>
                      <Text fontSize="3xl">{patient.name}</Text>
                      <Text fontSize="1xl">Date of birth: {parseDate(patient.dateOfBirth, 'L')}</Text>
                      {map(patient.contact, (contact) => (
                        <Text fontSize="1xl">{contact.value}</Text>
                      ))}
                      <Text marginTop={12} fontSize="2xl">No appointments found.</Text>
                    </Box>
                  </Center>
                }
                {!patient && searchResults.result &&
                <Box bgColor="white" textAlign="center" marginTop={12} height="600px" overflowY="auto" overflowX="hidden">
                  <Table>
                    <TableCaption placement="top" fontSize="2xl">Patients Found</TableCaption>
                    <Thead>
                      <Tr>
                        <Th></Th>
                        <Th>Name</Th>
                        <Th>Date of Birth</Th>
                        <Th>Contact</Th>
                        <Th>Actions</Th>
                      </Tr>
                    </Thead>
                    <Tbody height="16">
                      {map(searchResults.result, (item: any) => {
                        const activeContacts = item.contact.filter((i: any) => i.active);
                        const contact = item.contact.length > 0 ? activeContacts : '';
                        const isSecondDoseLoading = mutation.variables === item._id && mutation.isLoading;
                        return (
                          <Tr key={item._id}>
                            <Td>
                              <IconButton
                                size="sm"
                                variant="outline"
                                aria-label="Edit patient"
                                onClick={() => setPatientToEdit(item)}
                                colorScheme="blue"
                                icon={<EditIcon />}
                              />
                            </Td>
                            <Td>{item.name}</Td>
                            <Td>{parseDate(item.dateOfBirth, 'L')}</Td>
                            <Td>
                              {map(contact, (c: any) => (
                                <>
                                  <span>{c.value}</span><br />
                                </>
                              ))}
                            </Td>
                            <Td isNumeric>
                              <HStack>
                                <Button size="sm" onClick={() => setPatient(item)} colorScheme="green">Select Patient</Button>
                                <Button size="sm" isLoading={isSecondDoseLoading} onClick={() => handleBookSecondDose(item._id)} colorScheme="orange">
                                  Schedule Patient
                                </Button>
                              </HStack>
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </Box>
                }
              </TabPanel>
              <TabPanel>
                <EmbededScheduler />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
        {appointments && appointments.length > 0 &&
          <Flex flexDirection="column">
            <Box textAlign="center" marginBottom="8">
              <Heading>Patient</Heading>
              <Text fontSize="3xl">{patient.name}</Text>
              <Text fontSize="1xl">Date of birth: {parseDate(patient.dateOfBirth, 'L')}</Text>
              {map(patient.contact, (contact) => (
                <Text fontSize="1xl">{contact.value}</Text>
              ))}
            </Box>
            <Box bgColor="white" textAlign="center">
              <Table variant="simple">
                <TableCaption placement="top" fontSize="2xl">Appointments</TableCaption>
                <Thead>
                  <Tr>
                    <Th>date</Th>
                    <Th>Facility</Th>
                    <Th textAlign="center">Appointment Status</Th>
                    <Th textAlign="center">Appointment Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {appointments.map((item: any) => {
                    const isBooked = item.statusReason === 'arrived';
                    const isCancelled = item.status === 'cancelled';
                    const buttonType = {
                      label: isBooked ?
                      'Booked'
                      : isCancelled
                        ? 'Cancelled'
                      : 'Check In',
                      color: isBooked ?
                      'orange'
                      : isCancelled
                        ? 'red'
                      : 'green',
                      disabled: isBooked || isCancelled,
                    }
                    return (
                      <Tr key={item._id}>
                        <Td>{moment(item.date).format('LLLL')}</Td>
                        <Td>{item.facility.name}</Td>
                        <Td textAlign="center">
                          <Button
                              colorScheme={buttonType.color}
                              disabled={buttonType.disabled}
                              onClick={() => handlePatientCheckIn(item)}
                              size="sm"
                            >
                              {buttonType.label}
                            </Button>
                        </Td>
                        <Td textAlign="center">
                          {
                            !isBooked && !isCancelled &&
                              <>
                                <Button
                                  colorScheme="red"
                                  disabled={isBooked}
                                  onClick={() => setShowCancelConfirmation(item._id)}
                                  size="sm"
                                >
                                  {item.status === 'cancelled' ? 'Cancelled' : 'Cancel'}
                                </Button>
                                <CancelConfirmationDialog
                                  isOpen={showCancelConfirmation === item._id}
                                  onClose={() => setShowCancelConfirmation('')}
                                  onCancelAppointment={() => {
                                    handleCancelAppointment(item._id);
                                    setShowCancelConfirmation('');
                                  }}
                                />
                              </>
                          }
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Box>
          </Flex>
        }
        {
          appointmentsLoading &&
          <Stack>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
          </Stack>
        }
      </Flex>
      {patientToEdit &&
        <EditPatientDialog
          onClose={() => {
            setPatientToEdit(null);
          }}
          onSave={() => {
            // to invalidate cached memo
            setInputText(`${inputText} `);
          }}
          patient={patientToEdit}
        />
      }
    </>
  );
}

export default SearchStep;
