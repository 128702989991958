import moment from 'moment';
import { useForm } from 'react-hook-form';

import { uniqBy, isEmpty, map } from 'lodash';

import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  InputGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  Text,
} from "@chakra-ui/react";

import { WarningIcon } from '@chakra-ui/icons';

import { emailRegex, emailRegexMsg } from 'helpers/regexPatterns';

import useEditPatientMutation from './useEditPatientMutation';

function parseDate(value: any, format: string) {
  if (!value) return '';

  if (value.year) {
    const { year, month, day } = value;

    return moment(`${year}-${month}-${day}`, 'YYYY-MM-DD').format(format || 'LL');
  }

  return moment(value, 'YYYY-MM-DD').format(format || 'LL');
}

function getActiveContact(contacts: any, type: string) {
  const contact = contacts.filter((c: any) => c.active && c.type === type);
  return contact.length ? contact[0].value : '';
}

function getContactArray(newContacts: any) {
  const contacts = [];

  if (newContacts.email) {
    contacts.push(
      {
        active: true,
        type: 'email',
        value: newContacts.email,
      }
    );
  }
  if (newContacts.sms) {
    contacts.push(
      {
        active: true,
        type: 'sms',
        value: newContacts.sms,
      }
    );
  }
  if (newContacts.voice) {
    contacts.push(
      {
        active: true,
        type: 'voice',
        value: newContacts.voice,
      }
    );
  }

  return contacts;
}

const EditPatientDialog: React.FC<any> = ({ patient, onClose, onSave }) => {
  const mutation = useEditPatientMutation();
  const { register, errors, handleSubmit } = useForm({
    defaultValues: {
      firstname: patient.firstname,
      lastname: patient.lastname,
      dateOfBirth: parseDate(patient.dateOfBirth, 'YYYY-MM-DD'),
      sms: getActiveContact(patient.contact, 'sms'),
      email: getActiveContact(patient.contact, 'email'),
      voice: getActiveContact(patient.contact, 'voice'),
    },
  });

  const handleOnClose = () => {
    onClose();
  };

  const onSubmitClick = (data: any) => {
    const dobParsed = {
      year: data.dateOfBirth.split('-')[0],
      month: data.dateOfBirth.split('-')[1],
      day: data.dateOfBirth.split('-')[2],
    };
    const contacts = [
      ...getContactArray(data), // keep this order for the uniqBy
      ...patient.contact,
    ];

    mutation.mutate({ patientInfo: {
      ...data,
      dateOfBirth: dobParsed,
      contact: uniqBy(contacts, 'type'),
    }, patientId: patient._id }, {
      onSuccess: () => {
        onSave();
        onClose();
      },
    });
  };

  return (
    <>
      <Modal size="lg" isOpen={Boolean(patient)} onClose={handleOnClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Patient Info</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid
              alignItems="center"
              templateRows="repeat(7, auto)"
              templateColumns="1fr 1fr"
              gap={4}
            >
              <GridItem>
                <FormControl id="firstname">
                  <FormLabel>First Name</FormLabel>
                  <InputGroup>
                    <Input
                      type="text"
                      name="firstname"
                      placeholder="Enter first name"
                      variant="filled"
                      focusBorderColor="green.300"
                      isInvalid={errors.firstname}
                      errorBorderColor="red.300"
                      ref={
                        register({
                          required: { value: true, message: 'First name is required.'},
                          minLength: { value: 2, message: 'First name is too short.' },
                        })
                      }
                    />
                  </InputGroup>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl id="lastName">
                  <FormLabel>Last Name</FormLabel>
                  <InputGroup>
                    <Input
                      type="text"
                      name="lastname"
                      placeholder="Enter last name"
                      variant="filled"
                      focusBorderColor="green.300"
                      isInvalid={errors.lastname}
                      errorBorderColor="red.300"
                      ref={
                        register({
                          required: { value: true, message: 'Last name is required.'},
                          minLength: { value: 2, message: 'Last name is too short.' },
                        })
                      }
                    />
                  </InputGroup>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl id="dateOfBirth">
                  <FormLabel>Date of Birth</FormLabel>
                  <InputGroup>
                    <Input
                      type="date"
                      name="dateOfBirth"
                      placeholder="Enter date of birth"
                      variant="filled"
                      focusBorderColor="green.300"
                      isInvalid={errors.dateOfBirth as any}
                      errorBorderColor="red.300"
                      ref={
                        register({
                          required: { value: true, message: 'Date of Birth is required.' },
                        })
                      }
                    />
                  </InputGroup>
                </FormControl>
              </GridItem>
              <GridItem colSpan={2}>
                <FormControl id="voice">
                  <FormLabel>Landline</FormLabel>
                  <InputGroup>
                    <Input
                      type="tel"
                      name="voice"
                      placeholder="+1 000-000-0000"
                      variant="filled"
                      focusBorderColor="green.300"
                      isInvalid={errors.voice}
                      errorBorderColor="red.300"
                      ref={
                        register({
                          required: false,
                        })
                      }
                    />
                  </InputGroup>
                </FormControl>
              </GridItem>
              <GridItem colSpan={2}>
                <FormControl id="sms">
                  <FormLabel>Cell Phone (SMS)</FormLabel>
                  <InputGroup>
                    <Input
                      type="tel"
                      name="sms"
                      placeholder="+1 000-000-0000"
                      variant="filled"
                      focusBorderColor="green.300"
                      isInvalid={errors.sms}
                      errorBorderColor="red.300"
                      ref={
                        register({
                          required: false,
                        })
                      }
                    />
                  </InputGroup>
                </FormControl>
              </GridItem>
              <GridItem colSpan={2}>
                <FormControl id="email">
                  <FormLabel>Email</FormLabel>
                  <InputGroup>
                    <Input
                      type="email"
                      name="email"
                      placeholder="example@mail.com"
                      variant="filled"
                      focusBorderColor="green.300"
                      isInvalid={errors.email}
                      errorBorderColor="red.300"
                      ref={
                        register({
                          required: false,
                          pattern: { value: emailRegex, message: emailRegexMsg },
                        })
                      }
                    />
                  </InputGroup>
                </FormControl>
              </GridItem>
              {
                !isEmpty(errors) &&
                <>
                  <GridItem colSpan={2}>
                    <Stack>
                      {map(errors, (error) => {
                        const name = error.ref.name;
                        return (
                          <HStack key={`stack-${name}`}>
                            <WarningIcon color="red.400" w={4} h={4} />
                            <Text role="treeitem" key={name} color="red.500" fontSize="sm">{error.message}</Text>
                          </HStack>
                        );
                      })}
                    </Stack>
                  </GridItem>
                </>
              }
            </Grid>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" variant="ghost" mr={3} onClick={handleOnClose}>
              Cancel
            </Button>
            <Button
              colorScheme="green"
              isLoading={mutation.isLoading}
              onClick={handleSubmit(onSubmitClick)}
            >
              Update!
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default EditPatientDialog;
