import { useMutation } from 'react-query';

import { useAxiosContext } from 'contexts/AxiosContext';

type TPatientInfo = {
  patientInfo: {
    firstname: string;
    lastname: string;
    dateOfBirth: {
      year: string;
      month: string;
      day: string;
    };
    email?: string;
  }
  patientId: string;
}

const useEditPatientMutation = () => {
  const { axios } = useAxiosContext();

  return useMutation(
    ({ patientInfo, patientId }: TPatientInfo) => axios.put(`/patients/${patientId}`, patientInfo, {
      headers: {
        'x-access-token': window.localStorage.getItem('auth:token'),
      },
    }),
  );
};

export default useEditPatientMutation;
