import { useQuery } from 'react-query';

import { useAxiosContext } from 'contexts/AxiosContext';

const usePatientFormContextQuery = () => {
  const { axios } = useAxiosContext();
  const rootId = window.localStorage.getItem('auth:root');
  return useQuery('patientFormContext',
      () => axios.get(`/contexts/${rootId}`,
      {
        headers: {
          'x-access-token': window.localStorage.getItem('auth:token'),
        },
      }
    ),
  );
};

export default usePatientFormContextQuery;
