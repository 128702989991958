import React, { createContext, useContext, useMemo, useState } from 'react';
import Axios, { AxiosInstance } from 'axios';

import { API } from '../constants';

import { useAuthContext } from './AuthContext';

const initialValue = {
  axios: Axios.create({
    baseURL: API,
    headers: {
      "Content-Type": "application/json",
    },
  }),
  setHeadersAuthToken: () => '',
};

const AxiosContext = createContext<{
  axios: AxiosInstance;
  setHeadersAuthToken: (token: string) => void;
}>(initialValue);

export const AxiosProvider: React.FC<any> = ({ children }) => {
  // hooks
  const { userSession } = useAuthContext();
  const { axios } = initialValue;
  const [authToken, setAuthToken] = useState(userSession.token);

  const axiosProviderValue = useMemo(() => {
    axios.interceptors.request.use((config) => {
      if (authToken) {
        config.headers['x-access-token'] = authToken;
      }

      return config;
    });

    return {
      axios,
      setHeadersAuthToken: (token: string) => {
        setAuthToken(token);
      },
    };
  }, [axios, authToken]);

  return (
    <AxiosContext.Provider value={axiosProviderValue}>
      {children}
    </AxiosContext.Provider>
  );
}

export const useAxiosContext = () => {
  const ctx = useContext(AxiosContext);

  if (typeof ctx === 'undefined') {
    throw new Error('useAuthContext must be used within AuthProvider');
  }

  return ctx;
};