import moment from 'moment';

import { Box, Button, Center, Heading, Stack, Text } from "@chakra-ui/react";

interface SuccessStepProps {
  onNext: () => void;
  booked: any;
}

const SuccessStep: React.FC<SuccessStepProps> = ({ onNext, booked }) => {
  if (!booked) return null;

  const { appointment, patient } = booked;

  return(
    <Center>
      <Box width="600px">
        <Stack spacing={2} textAlign="center" marginBottom={16}>
          <Heading>Success!</Heading>
          <Heading>You have checked in:</Heading>
        </Stack>
        <Stack spacing={2} marginBottom={16}>
          <Text fontSize="2xl"><strong>Patient:</strong> {patient.name}</Text>
          <Text fontSize="2xl"><strong>Facility:</strong> {appointment.facility.name}</Text>
          <Text fontSize="2xl"><strong>Date:</strong> {moment(appointment.date).format('LLLL')}</Text>
        </Stack>
        <Button colorScheme="orange" onClick={onNext} width="100%">New Patient Check In</Button>
      </Box>
    </Center>
  );
}

export default SuccessStep;
