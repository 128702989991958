import usePatientFormContextQuery from './usePatientFormContextQuery';
import usePatientVerifyMutation from './usePatientVerifyMutation';

const useBookSecondDose = () => {
  const mutation = usePatientVerifyMutation();
  const query = usePatientFormContextQuery();
  return {
    mutation,
    query,
  };
};

export default useBookSecondDose;
