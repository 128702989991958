import { Center, Heading } from '@chakra-ui/react';

const EmbededScheduler: React.FC<any> = () => {
  return (
    <Center marginTop={12}>
      <Heading>The scheduler has opened in a new tab.</Heading>
      {/* <iframe
        src="https://patient.lumahealth.io/survey?patientFormTemplate=601b6e60ff03d10012c7769c&user=601862d6447bbd001abb8540&isOnPatientBehalf=true"
        title="Scheduler"
      >
      </iframe> */}
    </Center>
  );
}

export default EmbededScheduler;
