import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { get } from 'lodash';

import { Alert, AlertIcon, AlertTitle, AlertDescription, Button, Center, Flex, FormControl, FormLabel, Heading, Input, Stack } from '@chakra-ui/react';

import { useAuthContext } from 'contexts/AuthContext';

import useLoginQuery from './useLoginQuery';

interface LoginProps {};

const Login: React.FC<LoginProps> = () => {
  const { push } = useHistory();
  const { logout } = useAuthContext();
  const mutation = useLoginQuery();
  // State
  const [form, setForm] = useState({ email: '', password: ''});

  // Clear local storage from old tokens
  useEffect(() => {
    logout();
  }, [logout]);

  const handleInputChange = (event: any) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  }

  const handleButtonClick = () => {
    mutation.mutate(form, {
      onSuccess: () => push('/patient'),
    })
  };

  return (
    <>
      <Center bgColor="#F0F6F7" width="100%" height="100vh">
        <Flex bgColor="white" padding="12" width="600px" flexDirection="column">
          <Heading>Cook County Vaccination</Heading>
          <Stack spacing={8} marginTop={8}>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input type="email" name="email" onChange={handleInputChange} value={form.email} />
            </FormControl>
            <FormControl id="email">
              <FormLabel>Password</FormLabel>
              <Input type="password" name="password" onChange={handleInputChange} value={form.password} />
            </FormControl>
            <Button isLoading={mutation.isLoading} colorScheme="orange" onClick={handleButtonClick}>Sign In</Button>
            {
              mutation.isError && (
              <Alert status="error">
                <AlertIcon />
                <AlertTitle mr={2}>Access denied!</AlertTitle>
                <AlertDescription>{get(mutation, 'error.response.data.message')}</AlertDescription>
              </Alert>
              )
            }
          </Stack>
        </Flex>
      </Center>
    </>
  );
};

export default Login;
