import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import { AxiosProvider } from 'contexts/AxiosContext';
import { AuthProvider } from 'contexts/AuthContext';

import Login from './Login';
import PatientAppointment from './PatientAppointment';

function App() {
  return (
    <AxiosProvider>
      <AuthProvider>
        <Router>
          <Switch>
            <Route path="/patient">
              <PatientAppointment />
            </Route>
            <Route path="/">
              <Login />
            </Route>
          </Switch>
        </Router>
      </AuthProvider>
    </AxiosProvider>
  );
}

export default App;
