import { useState } from 'react';
import { Box, Button, Flex, Image } from "@chakra-ui/react";
import { ArrowForwardIcon } from '@chakra-ui/icons';

import SearchStep from './SearchStep';
import SuccessStep from './SuccessStep';
import { useHistory } from 'react-router-dom';

const SEARCH_STEP = 'searchStep';
const SUCCESS_STEP = 'successStep';

function PatientAppointment() {
  const { push } = useHistory();
  const [currentStep, setCurrentStep] = useState(SEARCH_STEP);
  const [booked, setBooked] = useState<any>();
  const logoSrc = "https://lumahealth-custom-styles-prod.s3-accelerate.amazonaws.com/601862d550b18b0012f44921/1612478900434_community-vaccination-program-logo__328x42_@2x.png";

  const handleOnLogout = () => {
    window.localStorage.removeItem('auth:token');
    window.localStorage.removeItem('auth:root');
    push('/');
  }

  return (
    <Flex flexDirection="column" height="100vh">
      <Flex height="20" paddingLeft="2" paddingRight="4"  justifyContent="space-between" alignItems="center">
        <Box>
          <Image src={logoSrc} alt="Community Vaccination" />
        </Box>
        <Button onClick={handleOnLogout} size="sm" rightIcon={<ArrowForwardIcon />} colorScheme="blue" variant="outline">
          Logout
        </Button>
      </Flex>
      <Flex justifyContent="center" padding={12} bgColor="#F0F6F7" width="100%" height="100%">
        {currentStep === SEARCH_STEP &&
          <SearchStep
            onNext={(data) =>
              {
                setCurrentStep(SUCCESS_STEP);
                setBooked(data);
              }
            }
          />
        }
        {currentStep === SUCCESS_STEP &&
          <SuccessStep
            booked={booked}
            onNext={() => {
              setCurrentStep(SEARCH_STEP);
            }}
          />
        }
      </Flex>
    </Flex>
  );
}

export default PatientAppointment;
