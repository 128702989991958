import { useMutation } from 'react-query';

import { useAxiosContext } from 'contexts/AxiosContext';

const usePatientVerifyMutation = () => {
  const { axios } = useAxiosContext();

  return useMutation(
    (patientId: string) =>
      axios.post(`/patients/${patientId}/verify`,
      {
        code: 'NO-2FA-CODE',
      },
      {
        headers: {
          'x-access-token': window.localStorage.getItem('auth:token'),
        },
      },
    ),
  );
};

export default usePatientVerifyMutation;
